window.onGrecaptchaLoad = () => {
  window.grecaptcha.ready(() => {
    const action = "load";
    window.grecaptcha.execute(window.gon.RECAPTCHA_V3_SITE_KEY, { action }).then((token) => {
      fetch("/recaptcha/verify", {
        method: "post",
        creadentials: "include",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, recaptcha_action: action }),
      }).then((response) => {
        response.json().then((data) => {
          if (data.action !== action) return;

          window.meetabitVisitorScore = data.score;
        });
      });
    });
  });
};
